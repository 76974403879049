import React, { FC, useEffect, useRef, useState } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchFormSmall, {
  SearchTab,
} from "components/HeroSearchFormSmall/HeroSearchFormSmall";
import { useLocation, Link } from "react-router-dom";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { getCurrentUser, logout } from "services/auth.service";
import { User } from "types/auth.types";

interface Header3Props {
  className?: string;
}

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const [showHeroSearch, setShowHeroSearch] = useState<StaySearchFormFields | null>(null);
  const [currentTab, setCurrentTab] = useState<SearchTab>("Otel");
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const isDarkMode = localStorage.theme === "dark";
  
  useEffect(() => {
    setCurrentUser(getCurrentUser());

    const handleStorageChange = () => {
      setCurrentUser(getCurrentUser());
    };

    window.addEventListener('storage', handleStorageChange);
    
    const handleAuthChange = () => {
      setCurrentUser(getCurrentUser());
    };
    window.addEventListener('authChange', handleAuthChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('authChange', handleAuthChange);
    };
  }, []);

  const handleLogout = () => {
    logout();
    setCurrentUser(null);
    setShowUserMenu(false);
    // refresh page
    window.location.reload();
  };

  var localStorageFormFlightData = localStorage.getItem("formFlightData");
  if (localStorageFormFlightData){
    var formFlightData = JSON.parse(localStorageFormFlightData);
    formFlightData.flightDates.departureDate = new Date(formFlightData.flightDates.departureDate);
    if (formFlightData.flightDates.returnDate)
      formFlightData.flightDates.returnDate = new Date(formFlightData.flightDates.returnDate);
  }

  useOutsideAlerter(headerInnerRef, () => {
    setShowHeroSearch(null);
    setCurrentTab("Otel");
  });

  let location = useLocation();

  useEffect(() => {
    setShowHeroSearch(null);
  }, [location]);

  const handleFlightClick = () => {
    setShowHeroSearch("location");
    setCurrentTab("Uçak");
  };

  const handleHotelClick = () => {
    setShowHeroSearch("dates");
    setCurrentTab("Otel");
  };

  const handleTransferClick = () => {
    setShowHeroSearch("guests");
    setCurrentTab("Transfer");
  };

  const renderHeroSearch = () => {
    return (
      <div
        className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${
          showHeroSearch
            ? "visible"
            : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
        }`}
      >
        <div className={`w-full max-w-4xl mx-auto pb-6`}>
          <HeroSearchFormSmall
            defaultFieldFocus={showHeroSearch || undefined}
            onTabChange={setCurrentTab}
            defaultTab={currentTab}
            formFlightData={formFlightData}
          />
        </div>
      </div>
    );
  };

  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
          showHeroSearch
            ? "-translate-x-0 translate-y-20 scale-x-[2.55] scale-y-[1.8] opacity-0 pointer-events-none invisible"
            : "visible"
        }`}
      >
        <div className="flex items-center font-medium text-sm">
          <span
            onClick={handleFlightClick}
            className="block pl-5 pr-4 cursor-pointer py-3"
          >
            Uçak
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            onClick={handleHotelClick}
            className="block px-4 cursor-pointer py-3 "
          >
            Otel
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            onClick={handleTransferClick}
            className="block px-4 cursor-pointer font-normal py-3"
          >
            Transfer
          </span>
        </div>

        <div
          className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
          onClick={handleFlightClick}
        >
          <span className="w-8 h-8 flex items-center justify-center rounded-full text-neutral-50 bg-[black] hover:bg-[#333333] focus:outline-none dark:hover:bg-[#1a1a1a]">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    );
  };

  const renderUserMenu = () => {
    if (!currentUser) {
      return (
        <div className="flex items-center space-x-4">
          <Link
            to="/login"
            state={{ from: location }}
            className="text-sm font-medium hover:text-primary-900 fa fa-user"
            title="Giriş Yap"
          >
           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
              <path d="M10 17l5-5-5-5"></path>
              <path d="M15 12H3"></path>
          </svg>
          </Link>
        </div>
      );
    }

    return (
      <div className="relative">
        <button
          onClick={() => setShowUserMenu(!showUserMenu)}
          className="flex items-center space-x-2 focus:outline-none"
        >
          <div className={`w-8 h-8 rounded-full ${isDarkMode ? "bg-white text-black" : "bg-black text-white"} flex items-center justify-center`}>
            {currentUser.firstName.charAt(0)}
          </div>
        </button>

        {showUserMenu && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
            <Link
              to="/account"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Hesabım
            </Link>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Çıkış Yap
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] ${
          showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
        }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? "duration-75" : ""} 
          ${
            showHeroSearch
              ? currentTab === "Uçak"
                ? "scale-y-[4.4]"
                : "scale-y-[3.4]"
              : ""
          }`}
        ></div>
        <div className="relative px-4 lg:container h-[88px] flex">
          <div className="flex-1 flex items-center justify-between">
            <div className="relative z-10 hidden md:flex flex-1">
              <Logo />
            </div>

            <div className="flex-[2] lg:flex-none mx-auto">
              <div className="hidden lg:block">
                {renderButtonOpenHeroSearch()}
              </div>
              <div className="lg:hidden w-full max-w-lg mx-auto">
                <HeroSearchForm2MobileFactory />
              </div>
              {renderHeroSearch()}
            </div>

            <div className="hidden md:flex relative z-10 flex-1 items-center justify-end text-neutral-700 dark:text-neutral-100">
              <div className="items-center flex space-x-4">
                <SwitchDarkMode />
                {renderUserMenu()}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header3;