import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateSavedPassengerModel, SavedPassenger } from "types/auth.types";
import {
  getSavedPassengers,
  createSavedPassenger,
  updateSavedPassenger,
} from "services/passenger.service";
import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/build/css/intlTelInput.css';
import Select from "shared/Select/Select";
import { getCurrentUser } from "services/auth.service";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";

export interface PassengerFormProps {
  className?: string;
}

const PassengerForm: React.FC<PassengerFormProps> = ({ className }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const currentUser = getCurrentUser();
  const [formData, setFormData] = useState<CreateSavedPassengerModel>({
    identityNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: new Date(),
    phoneNumber: "",
    email: "",
    passengerType: "ADULT",
    relation: "SELF",
    isDefault: false,
    gender: -1,
    nationality: ""
  });

  const [countryCode, setCountryCode] = useState<string>("+90");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
      return;
    }

    if (id) {
      loadPassenger(id);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const loadPassenger = async (id: string) => {
    try {
      const passengers = await getSavedPassengers();
      const passenger = passengers.find((p) => p.id === parseInt(id));
      if (passenger) {
        setFormData({
          identityNumber: passenger.identityNumber,
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          dateOfBirth: new Date(passenger.dateOfBirth),
          phoneNumber: passenger.phoneNumber || "",
          email: passenger.email || "",
          passengerType: passenger.passengerType,
          relation: passenger.relation,
          isDefault: passenger.isDefault,
          gender: passenger.gender,
          nationality: passenger.nationality
        });

        if (passenger.phoneNumber) {
          setPhoneValue(passenger.phoneNumber);
        }
      }
    } catch (err: any) {
      setError(err.response?.data?.message || "Yolcu bilgileri yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      const submitData = {
        ...formData,
        phoneNumber: phoneValue ? `${phoneValue}` : undefined
      };

      if (id) {
        await updateSavedPassenger(id, submitData);
      } else {
        await createSavedPassenger(submitData);
      }
      navigate("/account-passengers");
    } catch (err: any) {
      setError(err.response?.data?.message || "Yolcu kaydedilirken bir hata oluştu");
    }
  };

  return (
    <div className={`nc-PassengerForm ${className}`} data-nc-id="PassengerFormPage">
    <Helmet>
      <title>TODOGO | Yolcu Ekle</title>
    </Helmet>
    <CommonLayout>
      <div className="space-y-6 sm:space-y-8">
        <h2 className="text-3xl font-semibold">
          {id ? "Yolcu Düzenle" : "Yeni Yolcu Ekle"}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        
        <div className="max-w-2xl mx-auto">
          <div className="shadow rounded-lg p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="text-sm text-red-700">{error}</div>
              </div>
            )}

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium">
                  TC Kimlik No
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.identityNumber}
                  maxLength={11}
                  minLength={11}
                  pattern="\d{11}"
                  onChange={(e) =>
                    setFormData({ ...formData, identityNumber: e.target.value })
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Ad
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Soyad
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Doğum Tarihi
                </label>
                <input
                  type="date"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.dateOfBirth.toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormData({ ...formData, dateOfBirth: new Date(e.target.value) })
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Telefon
                </label>
                <div className="flex relative w-100">
                  <IntlTelInput
                    initialValue={phoneValue}
                    onChangeNumber={setPhoneValue}
                    onChangeValidity={setIsPhoneValid}
                    onChangeCountry={(e) => {
                      setTimeout(() => {
                        const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
                        if (searchInput) {
                          searchInput.placeholder = "Ara";
                        }
                      }, 0);
                    }}
                    onChangeErrorCode={(e) => {
                      if (e !== null) {
                        const input = document.querySelector('.iti__tel-input');
                        input?.setAttribute('placeholder', e == 2 ? "Lütfen bu alanı doldurun." : errorMap[e]);
                        input?.classList.add('placeholder-red-400');
                      }
                    }}
                    initOptions={{
                      initialCountry: "tr",
                      separateDialCode: true,
                      strictMode: true,
                      loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
                      dropdownContainer: document.body
                    }}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium">
                  E-posta
                </label>
                <input
                  type="email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Cinsiyet
                </label>
                <select
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: parseInt(e.target.value) })
                  }
                >
                  <option value="-1">Seçiniz</option>
                  <option value="1">Erkek</option>
                  <option value="0">Kadın</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Uyruk
                </label>
                <Select
                  value={
                    formData.nationality
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, nationality: e.target.value })
                  }
                  className="border-neutral-200 focus:border-neutral-200 focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-200 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                >
                  <option value="">Seçiniz</option>
                  <option value="TR">Türkiye</option>
                  <option value="OTHER">Diğer Ülkeler</option>
                </Select>
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Yolcu Tipi
                </label>
                <select
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.passengerType}
                  onChange={(e) =>
                    setFormData({ ...formData, passengerType: e.target.value })
                  }
                >
                  <option value="ADULT">Yetişkin</option>
                  <option value="CHILD">Çocuk</option>
                  <option value="INFANT">Bebek</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium">
                  İlişki
                </label>
                <select
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  value={formData.relation}
                  onChange={(e) =>
                    setFormData({ ...formData, relation: e.target.value })
                  }
                >
                  <option value="SELF">Kendim</option>
                  <option value="SPOUSE">Eş</option>
                  <option value="CHILD">Çocuk</option>
                  <option value="PARENT">Ebeveyn</option>
                  <option value="OTHER">Diğer</option>
                </select>
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                checked={formData.isDefault}
                onChange={(e) =>
                  setFormData({ ...formData, isDefault: e.target.checked })
                }
              />
              <label className="ml-2 block text-sm">
                Varsayılan yolcu olarak ayarla
              </label>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => navigate("/account-passengers")}
                className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                İptal
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {id ? "Güncelle" : "Kaydet"}
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
    </CommonLayout>
    </div>
  );
};

export default PassengerForm; 