import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "services/auth.service";
import { SavedPassenger } from "types/auth.types";
import {
  getSavedPassengers,
  createSavedPassenger,
  updateSavedPassenger,
  deleteSavedPassenger,
} from "services/passenger.service";
import { Helmet } from "react-helmet-async";
import CommonLayout from "./CommonLayout";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: React.FC<AccountPageProps> = ({ className }) => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>TODOGO | Hesabım</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Hesabım</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow  max-w-3xl space-y-6">
              <div>
                <Label>Ad</Label>
                <Input className="mt-1.5" defaultValue={currentUser?.firstName} />
              </div>
              <div>
                <Label>Soyad</Label>
                <Input className="mt-1.5" defaultValue={currentUser?.lastName} />
              </div>
              <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue={currentUser?.email} />
              </div>
              <div>
                <Label>Telefon</Label>
                <Input className="mt-1.5" defaultValue={currentUser?.phoneNumber} />
              </div>
              <div className="pt-2">
                <ButtonPrimary>Güncelle</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
