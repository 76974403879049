import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC, useCallback } from "react";
import ClearDataButton from "./ClearDataButton";
import axios from "axios";
import { SearchItem } from "types/common.types";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  value?: string;
  onChange?: (value: SearchItem) => void;
  hasError?: boolean;
  type: 'hotel' | 'flight' | 'car' | 'experiences';
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Nereye",
  desc = "Nereye gitmek istiyorsunuz?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  value: propsValue = "",
  onChange,
  hasError = false,
  type,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(propsValue);
  const [valueList, setValueList] = useState<SearchItem[]>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [delay, setDelay] = useState<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const eventClickOutsideDiv = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current || !showPopover) return;
      if (!containerRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    },
    [showPopover]
  );

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("click", eventClickOutsideDiv);
    }
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover, eventClickOutsideDiv]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const searchAirport = async (airport:string) => {
    if (airport.length >= 3){
      var response = await axios.get("/api/airport/search/"+airport);
      setValueList(response.data);
    }
  }

  const handleSelectLocation = (item: SearchItem) => {
    setValue(item.display);
    setShowPopover(false);
    if (onChange) {
      onChange(item);
    }
  };


  const renderRecentSearches = () => {
    const searches = type === 'hotel'
      ? [
        {display:"İstanbul, Türkiye", code:"ASD"},
        {display:"Ankara, Türkiye", code:"DSA"},
        {display:"Antalya, Türkiye", code:"XCX"},
        {display:"Bursa, Türkiye", code:"ACQ"},
        {display:"Eskişehir, Türkiye", code:"EQW"}
        ]
      : [
          {display:"İstanbul, Türkiye (Tüm Havalimanları)", code:"ISTA"},
          {display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", code:"SAW"},
          {display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", code:"IST"},
          {display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", code:"ESB"},
          {display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", code:"ADB"}
        ];

    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Popüler Aramalar
        </h3>
        <div className="mt-2">
          {searches.map((item:SearchItem) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.code}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.display}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {

    var searches:SearchItem[] = [];

    if (type === 'hotel'){
      searches = [
        {display:"İstanbul, Türkiye", code:"ASD"},
        {display:"Ankara, Türkiye", code:"zxc"},
        {display:"Antalya, Türkiye", code:"zca"},
        {display:"Bursa, Türkiye", code:"weq"},
        {display:"Eskişehir, Türkiye", code:"EdSB"}
      ];
    }else{
      searches = valueList
    }


    return (
      <>
        {searches.map((item:SearchItem) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.code}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.display}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex flex-1`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              const newValue = e.currentTarget.value;
              console.log(newValue);
              setValue(newValue);

              if (onChange) {
                onChange(newValue as any);
              }
              // Eğer 3 karakterden fazla değilse istek yapılmaz
              if (newValue.length >= 3) {
                // Önceki timeout'u iptal ediyoruz
                if (delay) clearTimeout(delay);

                // 300ms gecikme ile yeni timeout başlatıyoruz
                setDelay(
                  setTimeout(async () => {
                    if (type === 'flight'){
                      await searchAirport(newValue);
                    }
                  }, 300)
                );
              }
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light">
            <span className={hasError ? "text-red-400" : "text-neutral-400"}>
              {!!value ? placeHolder : desc}
            </span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                if (onChange) {
                  onChange(null as any);
                }
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (!value || valueList.length > 0) && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value.length >= 3 ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
