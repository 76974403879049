/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import NcModal from "shared/NcModal/NcModal";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { FlightCardProps } from "components/FlightCard/FlightCard";
import Heading2 from "components/Heading/Heading2";
import Select from "shared/Select/Select";
import paymentCardsImage from "images/payment-cards.png";
import useCountdown from "./useCountdown";
import IntlTelInput, { intlTelInput } from 'intl-tel-input/react';
import 'intl-tel-input/build/css/intlTelInput.css';
import { Helmet } from "react-helmet-async";

import Checkbox from "shared/Checkbox/Checkbox";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AirBooking, Flight, PaymentResponse, FlightSegment, InstallmentOption, ShoppingCartResponse, FlightAllocateResponse, Passenger, FlightReservationResponse } from "types/flight.types";
import { getSavedPassengers } from "services/passenger.service";
import { SavedPassenger } from "types/auth.types";
import { getCurrentUser } from "services/auth.service";
import { logCheckoutEvent } from "utils/analytics";


export interface CheckOutPagePageMainProps {
  className?: string;
  selectedPackage?: string;
}


interface FlightData {
  shoppingCartId: string;
  passengers: Array<{
    PaxReferenceId:string;
    PassengerType:string;
  }>
  selectedDepartFlight: FlightCardProps["data"] | null;
  selectedReturnFlight: FlightCardProps["data"] | null; 
  isAllocated: boolean;
  isPrebooked: boolean;
  installmentOptions: Array<InstallmentOption>;
}

interface PaymentResult {
  success: boolean;
  message: string;
}

const CheckoutFlightCard: React.FC<{
  flight: FlightCardProps["data"];
  type: string;
}> = ({ flight, type }) => {
  const isMultiSegment = flight.flightInfo.segment.length > 1;

  return (
    
    <div className="nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden">
      {/* FLIGHT ROUTE */}
      <Helmet>
        <title>TODOGO | Bilet Rezervasyonu</title>
      </Helmet>
      {flight.flightInfo.segment.map((segment, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <div className="my-8 border-t border-neutral-200 dark:border-neutral-700"></div>
          )}
          <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
            {/* LOGO IMG */}
            <div className="w-full sm:w-24 flex-shrink-0 flex items-center justify-center">
            <img src={require(`../../images/airlines/${segment.airlineName}.png`)} style={{width: "100%", height: "60px", objectFit: "contain"}} alt="" />
            </div>

            {/* FLIGHT INFO */}
            <div className="flex-grow flex flex-row justify-between">
              <div className="text-center flex-1">
                <div className="font-medium">{segment.marketingAirlineName}</div>
                <div className="text-sm text-neutral-500">
                  {flight.flightType}
                </div>
              </div>
              <div className="text-center flex-1">
                <div className="font-medium">
                  {segment.departureCode} - {segment.arrivalCode}
                </div>
                <div className="text-sm text-neutral-500">
                  {segment.flightCode}
                </div>
              </div>
              <div className="text-center flex-1">
                <div className="font-medium">
                  {segment.departureTime} - {segment.arrivalTime}
                </div>
                <div className="text-sm text-neutral-500">
                  {segment.flightDuration}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}

      {/* PRICE */}
    </div>
  );
};

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
  selectedPackage,
}) => {
  const [invoiceType, setInvoiceType] = useState("Bireysel");

  const handleInvoiceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInvoiceType(e.target.value);
  };

  const location = useLocation();
  
  const formatDateObject = (date: Date) => {
    return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' });
  };
  
  const intlTelInputRef = useRef<any>(null);

  const [flightData, setFlightData] = useState<FlightData | null>(null);
  const [paymentClassName, setPaymentClassName] = useState("disabled");
  const [paymentInputsDisabled, setPaymentInputsDisabled] = useState(true);
  const [buttonMessage, setButtonMessage] = useState<React.ReactNode>("Ödemeye İlerle");
  const [isPhoneValid, setIsPhoneValid ]= useState(false);
  const [installmentOptions, setInstallmentOptions] = useState<InstallmentOption[]>([]);
  const [savedPassengers, setSavedPassengers] = useState<SavedPassenger[]>([]);
  const [selectedPassengers, setSelectedPassengers] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (location.state) {
      setFlightData(location.state as FlightData);
    } else {
      // ShoppingCartId ile gelen durumu kontrol et
      const params = new URLSearchParams(location.search);
      const cartId = params.get('ShoppingCartId');
      if (cartId) {
        // API'den bilgileri getir
        fetchShoppingCartData(cartId);
      } else {
        navigate('/listing-flights');
      }
    }
    logCheckoutEvent('flight');
  }, [location]);

  const fetchShoppingCartData = async (cartId: string) => {
    try {
      const response = await axios.get<ShoppingCartResponse>(`/api/flight/cart/${cartId}`);
      if (!response.data.hasError) {
        const cartData = response.data.shoppingCart;
        var flights = cartData.airBookings.map((flight: AirBooking) => ({
          id: flight.id,
          selectedPackage: flight.fareCode,
          flightGuests: {
            adultGuests: cartData.passengers.filter((p: any) => p.PaxType === 'ADT').length,
            childrenGuests: cartData.passengers.filter((p: any) => p.PaxType === 'CHD').length,
            infantGuests: cartData.passengers.filter((p: any) => p.PaxType === 'INF').length,
            totalGuests: cartData.passengers.length,
          },
          flightLocations: {
            departureLocation: flight.flightSegments[0].departureAirportName,
            departureLocationCode: flight.flightSegments[0].departureAirportCode,
            arrivalLocation: flight.flightSegments[0].arrivalAirportName,
            arrivalLocationCode: flight.flightSegments[0].arrivalAirportCode,
          },
          flightDates: {
            departureDate: new Date(flight.flightSegments[0].departureDateTime),
            returnDate: flight.flightSegments.length > 1 ? new Date(flight.flightSegments[1].departureDateTime) : null,
          },
          flightInfo: {
            segment: flight.flightSegments.map((segment: FlightSegment) => ({
              airlineName: segment.markettingAirline,
              departureCode: segment.departureAirportCode,
              arrivalCode: segment.arrivalAirportCode,
              flightCode: segment.flightNumber,
              departureTime: segment.departureDateTime.split('T')[1].slice(0, 5),
              arrivalTime: segment.arrivalDateTime.split('T')[1].slice(0, 5),
              flightDuration: `${Math.floor(segment.journeyDurationInMinute / 60)} saat ${segment.journeyDurationInMinute % 60} dakika`,
            })),
          },
          price: flight.totalFare,
          flightType: flight.flightSegments.length > 1 ? "Aktarmalı Uçuş" : "Direkt Uçuş",
          flightDirection: flight.flightSegments.length > 1 ? "Gidiş - Dönüş" : "Tek Yön",
        }));
        setFlightData({
          shoppingCartId: cartId,
          passengers: cartData.passengers.map((p: any) => ({ PaxReferenceId: p.PaxReferenceId, PassengerType: p.PaxType })),
          selectedDepartFlight: flights[0] as FlightCardProps["data"],
          selectedReturnFlight: flights.length > 1 ? flights[1] as FlightCardProps["data"] : null,
          isAllocated: true,
          isPrebooked: true,
          installmentOptions: cartData.paymentOption.installmentOptions
        });

        // Form verilerini doldur
        const initialFormData: { [key: string]: string } = {};
        cartData.passengers.forEach((passenger: any, index: number) => {
          initialFormData[`passengerName${index}`] = passenger.FirstName;
          initialFormData[`passengerSurname${index}`] = passenger.LastName;
          initialFormData[`passengerBirthDate${index}`] = formatDate(passenger.BirthDate);
          initialFormData[`passengerGender${index}`] = passenger.Gender.toLowerCase();
          initialFormData[`passengerNationality${index}`] = passenger.Nationality;
          if (passenger.Nationality === 'TR' ) {
            initialFormData[`passengerIdentityNumber${index}`] = passenger.CitizenNo;
          } else {
            initialFormData[`passengerPassportNumber${index}`] = passenger.PassportNo;
            initialFormData[`passengerPassportExpiry${index}`] = formatDate(passenger.PassportExpireDate);
          }
        });

        // İletişim bilgilerini doldur
        initialFormData.contactName = cartData.contact.name;
        initialFormData.contactSurname = cartData.contact.lastName;
        initialFormData.contactPhone = cartData.contact.phone;
        initialFormData.contactEmail = cartData.contact.email;
        
        // set IntlTelInput input value
        const phone = cartData.contact.phone;
        setPhoneValue(phone);
        if (intlTelInputRef.current) {
          intlTelInputRef.current.getInstance().setNumber(phone);
        }


        var invoiceInfo = JSON.parse(localStorage.invoiceInfo);
        // Fatura bilgilerini doldur
        initialFormData.invoiceType = invoiceInfo.Type;
        initialFormData.invoiceCity = invoiceInfo.City;
        initialFormData.invoiceDistrict = invoiceInfo.District;
        initialFormData.invoiceAddress = invoiceInfo.Address;
        initialFormData.billingName = invoiceInfo.BillingName;
        initialFormData.taxOffice = invoiceInfo.TaxOffice;
        initialFormData.taxNumber = invoiceInfo.TaxNumber;

        setFormData(initialFormData);
        
        // Ödeme alanlarını aktif et
        setPaymentClassName("");
        setPaymentInputsDisabled(false);
        
        // Toplam tutarı güncelle
        const [integerPart, decimalPart] = formatPrice(cartData.remainingTotal);
        const btnMessage = (
          <>
            {integerPart}
            <span>
              <span className="text-sm">,{decimalPart}</span> TL
            </span>
          </>
        );
        setFullTotal(cartData.remainingTotal);
        calculateTotalPrice();
        setButtonMessage(btnMessage);
      }
    } catch (error) {
      console.error('Shopping cart data fetch error:', error);
      navigate('/listing-flights');
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (flightData){
      if (!flightData!.isAllocated){
        allocateFLights();
      }
      console.log(flightData);
    }
  }, [flightData]);

  const allocateFLights = async () => {
    var allocateItems = [
      {
        ProductId: flightData!.selectedDepartFlight!.id,
        FareCode: flightData!.selectedDepartFlight!.selectedPackage
      }
    ]
    if (flightData!.selectedReturnFlight){
      allocateItems.push(
      {
        ProductId: flightData!.selectedReturnFlight.id,
        FareCode: flightData!.selectedReturnFlight.selectedPackage
      });
    }
    var response = await axios.post<FlightAllocateResponse>("/api/flight/allocate", {AllocateItems:allocateItems});
    if (!response.data.hasError && response.data.shoppingCartId){
      flightData!.isAllocated = true;
      flightData!.shoppingCartId = response.data.shoppingCartId;
      flightData!.passengers = response.data.passengers.map((p:Passenger) => ({ PaxReferenceId: p.paxReferenceId, PassengerType: p.paxType }));

      const initialFormData: { [key: string]: string } = {};
      const passengerCount =  flightData!.passengers.length

      for (let i = 0; i < passengerCount; i++) {
        initialFormData[`passengerNationality${i}`] = "TR";
      }

      setFormData(initialFormData);
      setFlightData(flightData);
      const [integerPart, decimalPart] = formatPrice(response.data.totalAmount);
      const btnMessage = (
        <>
          {integerPart}
          <span>
            <span className="text-sm">,{decimalPart}</span> TL
          </span>
        </>
      );
      setButtonMessage(btnMessage);
    }else{
      // show error message to user
      console.error("Flight allocation error:", response.data.errorMessage);
      navigate('/listing-flights');
    }
  }
  
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});


  const getLocationWithoutParentheses = (location: string | undefined) => {
    if (!location) return "";
    return location.replace(/\([^)]*\)/g, "").trim();
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState<{ [key: string]: string }>({});

  const [cardInfo, setCardInfo] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardType: "",
    installmentId: "",
  });

  const [cardErrors, setCardErrors] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const getPassengerTypeName = (paxType:string) => {
    switch (paxType){
      case "ADT": return "Yolcu";
      case "CHD": return "Çocuk";
      case "INF": return "Bebek";
    }
  }
  
  function organizeFormData(formData: { [key: string]: string }) {
    console.log("Form verisi düzenleniyor");
    const organizedData = {
      passengers: [] as any[],
      contactInfo: {} as any,
      invoiceInfo: {} as any,
      paymentResult: {} as any,
    };

    const passengerCount =
      Math.max(
        ...Object.keys(formData)
          .filter((key) => key.startsWith("passengerName"))
          .map((key) => parseInt(key.replace("passengerName", "")))
      ) + 1;

    for (let i = 0; i < passengerCount; i++) {
      const identityNumber = formData[`passengerIdentityNumber${i}`];
      console.log(`Passenger ${i} identity number:`, identityNumber);
      organizedData.passengers.push({
        name: formData[`passengerName${i}`] || "",
        surname: formData[`passengerSurname${i}`] || "",
        birthDate: formData[`passengerBirthDate${i}`] || "",
        identityNumber: formData[`passengerIdentityNumber${i}`] || "",
        nationality: formData[`passengerNationality${i}`] || "TR",
        passportNumber: formData[`passengerPassportNumber${i}`] || "",
        passportExpiry: formData[`passengerPassportExpiry${i}`] || "",
        gender: formData[`passengerGender${i}`] || "",
        paxType: flightData!.passengers[i].PassengerType,
        paxReferenceId: flightData!.passengers[i].PaxReferenceId,
      });
    }

    
    organizedData.contactInfo = {
      name: formData.contactName || "",
      surname: formData.contactSurname || "",
      phone: formData.countryCode ? `${formData.countryCode}${formData.contactPhone}` : formData.contactPhone,
      email: formData.contactEmail || "",
    };


    organizedData.invoiceInfo = {
      type: formData.invoiceType || "Bireysel",
      city: formData.invoiceCity || "",
      district: formData.invoiceDistrict || "",
      address: formData.invoiceAddress || "",
      billingName: formData.billingName || "",
      taxOffice: formData.taxOffice || "",
      taxNumber: formData.taxNumber || "",
      country: "TR"
    };

    return organizedData;
  }

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleTermsChange = (checked: boolean) => {
    setIsTermsAccepted(checked);
  };

  const isOnlyLetters = (str: string) => /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]+$/.test(str);

  const IsTroyCard = (binCode:string) => 
  {
      // Troy kartları için bilinen BIN kodları
      const troyBins = [ "365770", "365", "9792", "900", "654", "90000", "65" ];
      for (const bin in troyBins)
      {
        if (binCode.startsWith(bin))
        {
            return true;
        }
      }  
      return false;
  }

  const handleCardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;
    let error = "";

    switch (name) {
      case "cardHolder":
        formattedValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, "");
        error = formattedValue
          ? isOnlyLetters(formattedValue)
            ? ""
            : ""
          : "Lütfen geçerli bir kart sahibi adı ve soyadı girin.";
        break;
      case "cardNumber":
        formattedValue = value
          .replace(/\D/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim();
        formattedValue = formattedValue.substring(0, 19);
        const cardNumber = formattedValue.replace(/\s/g, "");
        if (cardNumber.length >= 6){
          // TODO: get installment options by bin number
          const binNumber = cardNumber.substring(0, 6);

          if (cardNumber.startsWith("4"))
          {
              cardInfo.cardType = "Visa";
          }
          
          // MasterCard kontrolü (51-55 ve 2221-2720 arası numaralar)
          if ((parseInt(cardNumber.substring(0, 2)) >= 51 && parseInt(cardNumber.substring(0, 2)) <= 55) ||
              (parseInt(cardNumber.substring(0, 4)) >= 2221 && parseInt(cardNumber.substring(0, 4)) <= 2720))
          {
            cardInfo.cardType = "MasterCard";
          }
          
          // Troy kartları için genişletilmiş kontrol
          if (IsTroyCard(binNumber))
          {
              cardInfo.cardType =  "Troy";
          }
          // Import and search arkman-bin.json
          const bankBins = require('./arkman-bin.json');
          const foundBank = bankBins.find((bank: any) => bank.code === binNumber);

          if (!foundBank) {
            // If bank not found, return only single installment
            const installments = flightData!.installmentOptions.filter(
              (option: InstallmentOption) => option.installmentCount === 1
            );
            setInstallmentOptions(installments);
            cardInfo.installmentId = installments[0].installmentOptionId;
          }else{
            // Get all installment options for this bank + single installment
            const bankInstallments = flightData!.installmentOptions.filter(
              (option: InstallmentOption) => 
                option.bankName === foundBank.Bank || 
                option.installmentCount === 1
            );
            // If no bank-specific options found, return single installment
            setInstallmentOptions(bankInstallments.length > 0 
            ? bankInstallments
            : flightData!.installmentOptions.filter(
                (option: InstallmentOption) => option.installmentCount === 1
              ));
          }
        }
        error =
        cardNumber.length === 16
            ? ""
            : "Lütfen geçerli bir kart numarası girin.";
        break;
      case "expiryDate":
        if (
          /^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate) &&
          value.length > cardInfo.expiryDate.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "");
        if (formattedValue.length > 2) {
          formattedValue =
            formattedValue.substring(0, 2) +
            "/" +
            formattedValue.substring(2, 4);
        }
        error = /^(0[1-9]|1[0-2])\/\d{2}$/.test(formattedValue)
          ? ""
          : "Lütfen geçerli bir son kullanma tarihi girin.";
        break;
      case "cvv":
        if (
          (cardInfo.cvv.length === 3 || cardInfo.cvv.length === 4) &&
          value.length > cardInfo.cvv.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "").substring(0, 4);
        error =
          formattedValue.length >= 3 && formattedValue.length <= 4
            ? ""
            : "Lütfen geçerli bir CVV girin.";
        break;
    }

    setCardInfo((prev) => ({ ...prev, [name]: formattedValue }));
    setCardErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isValidTCKN = (value: string) => /^[0-9]{11}$/.test(value);

  const isValidEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    let newValue = value;

    if ((name.includes('Name') && !name.includes('billingName')) || name.includes('Surname') || name === 'invoiceCity' || name === 'invoiceDistrict' || name === 'taxOffice') {
      newValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, '');
    } else if (name === 'billingName') {
      newValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, '');
    } else if (name.includes('BirthDate') || name.includes('PassportExpiry')) {
      newValue = value.replace(/[^\d/]/g, '');
      if (newValue.length === 2 || newValue.length === 5) {
        if (newValue.charAt(newValue.length - 1) !== '/') {
          newValue += '/';
        }
      }
      newValue = newValue.slice(0, 10);
    } else if (name.includes('IdentityNumber')) {
      newValue = value.replace(/\D/g, '').slice(0, 11);
    } else if (name.includes('PassportNumber')) {
      newValue = value.replace(/\D/g, '').slice(0, 9);
    } else if (name === 'contactPhone' || name === 'taxNumber') {
      newValue = value.replace(/\D/g, '');
      if (name === 'taxNumber') {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'contactEmail') {
      newValue = value.toLowerCase();
    } else if (name === 'billingName') {
      newValue = value;
    }

    setFormData(prev => {
      const updatedForm = { ...prev, [name]: newValue };
      
      if (name.includes('Nationality')) {
        console.log(name + " -> " + newValue);
        const index = name.replace('passengerNationality', '');
        if (newValue !== 'TR') {
          delete updatedForm[`passengerIdentityNumber${index}`];
          updatedForm[`passengerPassportNumber${index}`] = updatedForm[`passengerPassportNumber${index}`] || '';
          updatedForm[`passengerPassportExpiry${index}`] = updatedForm[`passengerPassportExpiry${index}`] || '';
        } else {
          updatedForm[`passengerIdentityNumber${index}`] = updatedForm[`passengerIdentityNumber${index}`] || '';
        }
      }
      
      return updatedForm;
    });
    
    if (newValue) {
      setFormErrors(prev => ({ ...prev, [name]: false }));
    }
  };

  const [countryCode, setCountryCode] = useState<string>("+90");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("5XX XXX XX XX");
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contactPhone: phoneValue,
    }));

  }, [phoneValue]);

  const validateForm = (formName:string) => {
    console.log(formData)
    console.log("Form doğrulama başlatıldı");
    const newErrors: { [key: string]: boolean } = {};
    const newCardErrors = { ...cardErrors };
    let isValid = true;
    let firstErrorField: string | null = null;

    if (formName === "passenger"){
      const passengerCount = flightData?.passengers.length || 0; 

      for (let i = 0; i < passengerCount; i++) {
        ["Name", "Surname", "BirthDate", "Gender", "Nationality"].forEach(
          (field) => {
            const key = `passenger${field}${i}`;
            if (!formData[key] || formData[key].trim() === "") {
              newErrors[key] = true;
              isValid = false;
            }
          }
        );

        if (formData[`passengerNationality${i}`] === "TR") {
          if (
            !formData[`passengerIdentityNumber${i}`] ||
            formData[`passengerIdentityNumber${i}`].length !== 11
          ) {
            newErrors[`passengerIdentityNumber${i}`] = true;
            isValid = false;
          }
        } else {
          if (
            !formData[`passengerPassportNumber${i}`] ||
            formData[`passengerPassportNumber${i}`].length !== 9
          ) {
            newErrors[`passengerPassportNumber${i}`] = true;
            isValid = false;
          }
          if (
            !formData[`passengerPassportExpiry${i}`] ||
            formData[`passengerPassportExpiry${i}`].length !== 10
          ) {
            newErrors[`passengerPassportExpiry${i}`] = true;
            isValid = false;
          }
          delete formData[`passengerIdentityNumber${i}`];
        }
      }


      ["contactName", "contactSurname", "contactPhone", "contactEmail"].forEach(
        (key) => {
          if (!formData[key]) {
            newErrors[key] = true;
            isValid = false;
          }
        }
      );


      ["invoiceCity", "invoiceDistrict", "invoiceAddress", "billingName"].forEach((key) => {
        if (!formData[key]) {
          newErrors[key] = true;
          isValid = false;
        }
      });


      if (!isValidEmail(formData.contactEmail)) {
        newErrors.contactEmail = true;
        isValid = false;
      }


      if (formData.invoiceType === "Kurumsal") {
        if (!formData.taxNumber || formData.taxNumber.length !== 10) {
          newErrors.taxNumber = true;
          isValid = false;
        }
        if (!formData.billingName) {
          newErrors.billingName = true;
          isValid = false;
        }
        if (!formData.taxOffice) {
          newErrors.taxOffice = true;
          isValid = false;
        }
      }


      ["invoiceCity", "invoiceDistrict", "taxOffice"].forEach((field) => {
        if (formData[field] && !isOnlyLetters(formData[field])) {
          newErrors[field] = true;
          isValid = false;
        }
      });

      if (!isPhoneValid){
        newErrors.contactPhone = true;
        isValid = false;
      }
      
    }else{
      Object.keys(cardInfo).forEach((field) => {
        if (!cardInfo[field as keyof typeof cardInfo].trim()) {
          newErrors[field] = true;
          newCardErrors[
            field as keyof typeof cardErrors
          ] = `Lütfen bu alanı doldurun.`;
          isValid = false;
        } else {
          newCardErrors[field as keyof typeof cardErrors] = "";
        }
      });
  
  
      if (cardInfo.cardNumber.replace(/\s/g, "").length !== 16) {
        newErrors.cardNumber = true;
        newCardErrors.cardNumber = "Lütfen bu alanı doldurun.";
        isValid = false;
      }
  
  
      if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate)) {
        newErrors.expiryDate = true;
        newCardErrors.expiryDate = "Lütfen bu alanı doldurun.";
        isValid = false;
      }
  
  
      if (cardInfo.cvv.length < 3 || cardInfo.cvv.length > 4) {
        newErrors.cvv = true;
        newCardErrors.cvv = "Lütfen bu alanı doldurun.";
        isValid = false;
      }
  
      if (!isTermsAccepted) {
        newErrors.terms = true;
        isValid = false;
      }
    }

    if (!isValid) {
        if (formName === "passenger") {
          firstErrorField = Object.keys(newErrors)[0];
          let errorMessage = "Lütfen aşağıdaki alanları kontrol ediniz:\n";
            
            // Yolcu bilgileri hataları
          Object.keys(newErrors).forEach(key => {
            var singleError = "";
              if (key.startsWith('passenger')) {
                  const passengerIndex = key.match(/\d+/)?.[0];
                  const fieldName = key.replace(/passenger|[0-9]/g, '');
                  
                  const fieldLabels: { [key: string]: string } = {
                      'Name': 'Ad',
                      'Surname': 'Soyad',
                      'BirthDate': 'Doğum Tarihi',
                      'Gender': 'Cinsiyet',
                      'Nationality': 'Uyruk',
                      'IdentityNumber': 'T.C. Kimlik No',
                      'PassportNumber': 'Pasaport No',
                      'PassportExpiry': 'Pasaport S.K.T.'
                  };
                  singleError = `• ${Number(passengerIndex) + 1}. Yolcu - ${fieldLabels[fieldName]}\n`;
                  errorMessage += `• ${Number(passengerIndex) + 1}. Yolcu - ${fieldLabels[fieldName]}\n`;
              }
              // İletişim bilgileri hataları
              else if (key.startsWith('contact')) {
                  const fieldLabels: { [key: string]: string } = {
                      'contactName': 'İletişim - Ad',
                      'contactSurname': 'İletişim - Soyad',
                      'contactPhone': 'İletişim - Telefon',
                      'contactEmail': 'İletişim - E-posta'
                  };
                  singleError += `• ${fieldLabels[key]}\n`;
                  errorMessage += `• ${fieldLabels[key]}\n`;
              }
              // Fatura bilgileri hataları
              else if (key.startsWith('invoice') && (key === 'billingName' || key === 'taxNumber' || key === 'taxOffice')) {
                  const fieldLabels: { [key: string]: string } = {
                      'invoiceCity': 'Fatura - İl',
                      'invoiceDistrict': 'Fatura - İlçe',
                      'invoiceAddress': 'Fatura - Adres',
                      'billingName': 'Fatura - Ad Soyad/Firma Adı',
                      'taxNumber': 'Fatura - Vergi No',
                      'taxOffice': 'Fatura - Vergi Dairesi'
                  };
                  singleError += `• ${fieldLabels[key]}\n`;
                  errorMessage += `• ${fieldLabels[key]}\n`;
              }
          });
          
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClick: () => {
              // Toast'a tıklandığında ilk hatalı alana focus at
              if (firstErrorField) {
                const element = document.querySelector(`[name="${firstErrorField}"]`) as HTMLElement;
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  setTimeout(() => {
                    element.focus();
                  }, 500); // Scroll tamamlandıktan sonra focus at
                }
              }
            },
            style: {
              whiteSpace: 'pre-line',
              cursor: 'pointer'
            }
          });
        } else {
            toast.error("Lütfen ödeme bilgilerini eksiksiz doldurunuz.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    setFormErrors(newErrors);
    setCardErrors(newCardErrors);
    console.log("Form doğrulama sonucu:", isValid);
    console.log("Form hataları:", newErrors);
    return isValid;
  };


  const [showErrors, setShowErrors] = useState(false);

  const simulatePayment = async (): Promise<PaymentResult> => {
    console.log("Ödeme simülasyonu başlatılıyor");
    return new Promise((resolve) => {
      setTimeout(() => {
        const success = Math.random() > 0.2;
        console.log("Ödeme simülasyonu sonucu:", success ? "Başarılı" : "Başarısız");
        resolve({ success, message: success ? "Ödeme başarılı" : "Ödeme başarısız" });
      }, 2000);
    });
  };

  const [generalError, setGeneralError] = useState<string | null>(null);

  const parseBirthDate = (str: string) => {
    const [day, month, year] = str.split("/").map(Number);
    // Tarihi UTC olarak oluştur ve ISO string'e çevir
    return new Date(Date.UTC(year, month - 1, day)).toISOString();
  }

  const handleProceedButton = async () => {
    console.log("asdasdasd");
    if (isTimeoutOccurred) {
      setShowTimeoutModal(true);
    } else {
      setShowErrors(true);
      
      if (flightData!.isPrebooked) {  
        if (validateForm("payment")){
          handlePayment();
        }
      }else{
        if (validateForm("passenger")) {
          const organizedData = organizeFormData(formData);
  
          var response = await axios.post<FlightReservationResponse>("/api/flight/reservation", {
            ShoppingCartId: flightData!.shoppingCartId,
            Contact:{
              Email: organizedData.contactInfo.email,
              Phone: organizedData.contactInfo.phone,
              Name: organizedData.contactInfo.name,
              LastName: organizedData.contactInfo.surname
            },
            Passengers: organizedData.passengers.map((x) => ({
              FirstName: x.name,
              LastName: x.surname,
              BirthDate: parseBirthDate(x.birthDate), // Düzeltilmiş tarih formatı
              CitizenNo: x.identityNumber,
              Nationality: x.nationality,
              PassportNo: x.passportNumber,
              PassportExpireDate: x.passportExpiry !== "" ? parseBirthDate(x.passportExpiry) : new Date().toISOString(),
              Gender: x.gender,
              Email: organizedData.contactInfo.email,
              Phone: organizedData.contactInfo.phone,
              PaxType: x.paxType,
              PaxReferanceId: x.paxReferenceId,
              PaxReferenceId: x.paxReferenceId,
              isChild: x.paxType !== 'ADT'
            })),
          });
  
          if (!response.data.hasError){
            flightData!.isPrebooked = true;
            flightData!.installmentOptions = response.data.shoppingCart.paymentOption.installmentOptions;
            
            const [integerPart, decimalPart] = formatPrice(response.data.shoppingCart.totalAmount);
            const btnMessage = (
              <>
                {integerPart}
                <span>
                  <span className="text-sm">,{decimalPart}</span> TL
                </span>
              </>);
            setFullTotal(response.data.shoppingCart.totalAmount);
            calculateTotalPrice();
            setFlightData(flightData);
            setPaymentClassName("");
            setPaymentInputsDisabled(false);
            setButtonMessage(btnMessage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }else{
            toast.error(response.data.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      }
    }
  }

  const handlePayment = async () => {
        try {
          const organizedData = organizeFormData(formData);
          var paymentResponse = await axios.post<PaymentResponse>("/api/flight/payment", {
            ShoppingCartId: flightData!.shoppingCartId,
            Amount: fullTotal,
            CardHolderName: cardInfo.cardHolder,
            CardNumber: cardInfo.cardNumber,
            ExpiryDate: cardInfo.expiryDate,
            CVV: cardInfo.cvv,
            CardType: cardInfo.cardType,
            InstallmentId: cardInfo.installmentId === "" ? flightData!.installmentOptions[0].installmentOptionId : cardInfo.installmentId,
            InvoiceInfo : {
              Type: organizedData.invoiceInfo.type,
              City: organizedData.invoiceInfo.city,
              District: organizedData.invoiceInfo.district,
              Address: organizedData.invoiceInfo.address,
              BillingName: organizedData.invoiceInfo.billingName,
              TaxOffice: organizedData.invoiceInfo.taxOffice,
              TaxNo: organizedData.invoiceInfo.taxNumber === "" ? (organizedData.passengers[0].nationality === "TR" ? organizedData.passengers[0].identityNumber : "00000000000") : organizedData.invoiceInfo.taxNumber,
              Country: organizedData.invoiceInfo.Country
            }
          });
          if (!paymentResponse.data.hasError) {
            window.location.href = paymentResponse.data.continueUrl;
            //navigate("/flight-pay-done", { state: { formData: organizedData,flightData: flightData } });
          } else {
            setGeneralError(paymentResponse.data.errorMessage);
          }
        } catch (error) {
          console.error("Ödeme işlemi sırasında hata:", error);
          setGeneralError("Ödeme işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
        }
    };

  const renderInput = (name: string, label: string, placeholder: string) => (
    <div className="flex-1 space-y-1">
      <Label>{label}</Label>
      <Input
        type="text"
        name={name}
        placeholder={
          formErrors[name] ? "Lütfen bu alanı doldurun." : placeholder
        }
        value={formData[name] || ""}
        onChange={handleInputChange}
        className={formErrors[name] ? "placeholder-red-400" : ""}
        disabled={!paymentInputsDisabled}
      />
    </div>
  );


  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split(".");
    return [integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."), decimalPart];
  };

  const [fullTotal, setFullTotal] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [totalDecimal, setTotalDecimal] = useState<string>("0");
  const calculateTotalPrice = () => {
    let total = fullTotal;
    const [integerPart, decimalPart] = formatPrice(total);
    setTotalPrice(integerPart);
    setTotalDecimal(decimalPart);
    return total;
  };

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const { minutes, seconds } = useCountdown(15, 0);
  const [isTimeoutOccurred, setIsTimeoutOccurred] = useState(false);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setShowTimeoutModal(true);
      setIsTimeoutOccurred(true);
    }
  }, [minutes, seconds]);

  const handleSearchAgainMain = () => {
    setShowTimeoutModal(false);
    navigate("/listing-flights");
  };


  const renderTimeoutModal = () => (
    <NcModal
      renderTrigger={() => null} 
      isOpenProp={showTimeoutModal}
      onCloseModal={() => setShowTimeoutModal(false)}
      modalTitle="Rezervasyon Bilgileri"
      contentExtraClass="max-w-screen-lg"
      renderContent={() => (
        <div className="text-md text-neutral-500 space-y-4">
        <span className="text-justify">
        TODOGO bu koltuğu sizin için ayırmıştı ancak size ayırılan süre
            içerisinde ödeme yapılmadığı için rezervasyonunuz iptal edildi. {" "} Üzgünüz, ancak{" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
              burayı
            </span>
            {" "}
            kullanarak{" "}
            
            yeniden 
            {" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
               arama {" "}
            </span>
            
            yapabilirsiniz. Merak etmeyin, sizin için burada olacağız.
          </span>
        </div>
      )}
    />
  );

  const renderSidebar = (paymentClassName: string) => {
      if(paymentClassName === 'disabled'){
        return (
        <div className="sticky top-32">
        <div className={`w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 ${paymentClassName}`}>
          <h3 className="text-2xl font-semibold">Ödeme Bilgileri</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* Ödeme Butonu */}

          <div className="space-y-4">
            <ButtonSecondary
              onClick={() => {
                handleProceedButton();
              }}
              className="w-full hover:bg-neutral-50 dark:hover:bg-black/20"
            >
              <div className="flex items-center justify-center space-x-2">
                <span className="text-xl font-semibold text-secondary-6000 break-normal">
                  {buttonMessage}
                </span>
                <i className="text-xl las la-angle-right text-secondary-6000"></i>
              </div>
            </ButtonSecondary>
            <p className="text-sm text-neutral-500 text-center">
              TODOGO üzerinden yapılan işlemler güvenlik sertifikalarıyla
              korunmaktadır.
              <i className="text-2xl las la-user-shield ml-1 text-secondary-6000"></i>
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 sticky mt-10">
          <h3 className="text-2xl font-semibold">Rezervasyon Bilgileri</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="space-y-4">
          <ButtonSecondary 
  className="w-full cursor-default"
  disabled={true}

  >
  <div className="flex items-center justify-center space-x-2">
    <span className="text-xl font-semibold text-secondary-6000 break-normal">
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </span>
  </div>
  </ButtonSecondary>
            <p className="text-sm text-neutral-500 text-center">
              TODOGO bu koltuğu sizin için ayırdı. Lütfen size ayırılmış süre
              içerisinde ödeme yapınız.
              <i className="text-2xl las la-user-clock ml-1 text-secondary-6000"></i>
            </p>
          </div>
        </div>
      </div>
        )
      }else{
        return (
          <div className="sticky top-32">
            <div className={`w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 ${paymentClassName}`}>
              <h3 className="text-2xl font-semibold">Ödeme Bilgileri</h3>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

              {/* Ödeme Yntemi Seçimi */}
              <div className="space-y-6">
                <div className="flex space-x-4">
                  <img
                    src={paymentCardsImage}
                    alt="Kabul edilen ödeme yöntemleri"
                    className="h-8 object-contain"
                  />
                </div>
              </div>

              {/* Kart Bilgileri Formu */}
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Label>Kart Sahibi</Label>
                    <Input
                      type="text"
                      name="cardHolder"
                      value={cardInfo.cardHolder}
                      onChange={handleCardInputChange}
                      placeholder="Ad Soyad"
                      className={`mt-1 ${cardErrors.cardHolder ? "" : ""}`}
                      disabled={paymentInputsDisabled}
                    />
                    {cardErrors.cardHolder && (
                      <p className="text-red-400 mt-1 w-full"
                      style={{fontSize: "13px"}}
                      >
                        {cardErrors.cardHolder}
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <Label>Kart Numarası</Label>
                    <Input
                      type="text"
                      name="cardNumber"
                      value={cardInfo.cardNumber}
                      onChange={handleCardInputChange}
                      placeholder="**** **** **** ****"
                      className={`mt-1 ${cardErrors.cardNumber ? "" : ""}`}
                      disabled={paymentInputsDisabled}
                    />
                    {cardErrors.cardNumber && (
                      <p className="text-red-400 mt-1 w-full"
                      style={{fontSize: "13px"}}
                      >
                        {cardErrors.cardNumber}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Label>Son Kullanma Tarihi</Label>
                    <Input
                      type="text"
                      name="expiryDate"
                      value={cardInfo.expiryDate}
                      onChange={handleCardInputChange}
                      placeholder="AA/YY"
                      className={`mt-1 ${cardErrors.expiryDate ? "" : ""}`}
                      disabled={paymentInputsDisabled}
                    />
                    {cardErrors.expiryDate && (
                      <p className="text-red-400 mt-1 w-full"
                      style={{fontSize: "13px"}}
                      >
                        {cardErrors.expiryDate}
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <Label>CVV</Label>
                    <Input
                      type="text"
                      name="cvv"
                      value={cardInfo.cvv}
                      onChange={handleCardInputChange}
                      placeholder="CVV"
                      className={`mt-1 ${cardErrors.cvv ? "" : ""}`}
                      disabled={paymentInputsDisabled}
                    />
                    {cardErrors.cvv && (
                      <p className="text-red-400 mt-1 w-full"
                      style={{fontSize: "13px"}}
                      >
                        {cardErrors.cvv}
                      </p>
                    )}
                  </div>
                </div>
                
                <div className="flex space-x-4">
                  {installmentOptions.length > 1 && (
                    <div className="flex-1">
                      <Label>Taksit</Label>
                      <Select
                        name="installmentId"
                        value={cardInfo.installmentId}
                        onChange={(e) => {
                          const { value } = e.target;
                          setCardInfo((prev) => ({ ...prev, installmentId: value }));
                        }}
                        disabled={paymentInputsDisabled}
                      >
                        {installmentOptions.map((option) => (
                          <option key={option.installmentOptionId} value={option.installmentOptionId}>
                            {option.installmentCount} Taksit
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              </div>

              {/* Onay Kutusu */}
              <div className="flex items-start space-x-3">
                <Checkbox
                  name="terms"
                  label=""
                  className="mt-1 flex-shrink-0"
                  defaultChecked={isTermsAccepted}
                  onChange={handleTermsChange}
                />
                <p
                  className={`text-sm text-neutral-500 ${
                    showErrors && !isTermsAccepted ? "text-red-400" : ""
                  }`}
                >
                  Kişisel verilerin işlenmesine ilikin{" "}
                  <NcModal
                    className="inline"
                    modalTitle="Aydınlatma Metni"
                    contentExtraClass="max-w-screen-lg"
                    renderTrigger={(openModal) => (
                      <span
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          openModal();
                        }}
                        className="underline cursor-pointer text-neutral-500"
                      >
                        Aydınlatma Metni'ni
                      </span>
                    )}
                    renderContent={() => (
                      <div className="text-md text-neutral-500 space-y-4">
                      <p className="text-justify">
                        Proident laborum officia ipsum do. Dolor ex culpa
                        nostrud proident laborum ut aliqua pariatur proident ut
                        pariatur qui ullamco Lorem. Deserunt consequat culpa
                        magna adipisicing sunt laborum. Aute Lorem ex dolore
                        magna anim sint duis anim esse nostrud ea.
                      </p>
                      <p className="text-justify">
                        Esse officia proident sunt ex cupidatat proident nisi
                        dolore dolor anim pariatur sint. Laboris excepteur est
                        dolor laboris est exercitation consequat in velit.
                        Occaecat aute exercitation in non officia consequat
                        occaecat duis ullamco consequat incididunt eiusmod.
                        Labore enim consequat fugiat laboris ullamco id
                        reprehenderit ipsum ad. Reprehenderit reprehenderit
                        deserunt fugiat adipisicing aliquip eiusmod quis.
                      </p>
                      <p className="text-jusify">
                        Veniam consectetur pariatur fugiat eiusmod. Excepteur
                        exercitation tempor minim elit aliqua eu eiusmod qui
                        magna deserunt nostrud Lorem labore. Velit duis occaecat
                        pariatur eu enim consequat eu laborum anim cupidatat
                        dolor deserunt id enim. Adipisicing et veniam duis
                        fugiat nisi nostrud. Nostrud excepteur consectetur
                        labore commodo veniam eu quis reprehenderit adipisicing
                        veniam.
                      </p>
                    </div>
                    )}
                  />{" "}
                  ve{" "}
                  <NcModal
                    className="inline"
                    modalTitle="Çerez Politikası Metni"
                    contentExtraClass="max-w-screen-lg"
                    renderTrigger={(openModal) => (
                      <span
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          openModal();
                        }}
                        className="underline cursor-pointer text-neutral-500"
                      >
                        Çerez Politikası Metni'ni
                      </span>
                    )}
                    renderContent={() => (
                      <div className="text-md text-neutral-500 space-y-4">
                      <p className="text-justify">
                        Proident laborum officia ipsum do. Dolor ex culpa
                        nostrud proident laborum ut aliqua pariatur proident ut
                        pariatur qui ullamco Lorem. Deserunt consequat culpa
                        magna adipisicing sunt laborum. Aute Lorem ex dolore
                        magna anim sint duis anim esse nostrud ea.
                      </p>
                      <p className="text-justify">
                        Esse officia proident sunt ex cupidatat proident nisi
                        dolore dolor anim pariatur sint. Laboris excepteur est
                        dolor laboris est exercitation consequat in velit.
                        Occaecat aute exercitation in non officia consequat
                        occaecat duis ullamco consequat incididunt eiusmod.
                        Labore enim consequat fugiat laboris ullamco id
                        reprehenderit ipsum ad. Reprehenderit reprehenderit
                        deserunt fugiat adipisicing aliquip eiusmod quis.
                      </p>
                      <p className="text-jusify">
                        Veniam consectetur pariatur fugiat eiusmod. Excepteur
                        exercitation tempor minim elit aliqua eu eiusmod qui
                        magna deserunt nostrud Lorem labore. Velit duis occaecat
                        pariatur eu enim consequat eu laborum anim cupidatat
                        dolor deserunt id enim. Adipisicing et veniam duis
                        fugiat nisi nostrud. Nostrud excepteur consectetur
                        labore commodo veniam eu quis reprehenderit adipisicing
                        veniam.
                      </p>
                    </div>
                    )}
                  />{" "}
                  okudum.{" "}
                  <NcModal
                    className="inline"
                    modalTitle="Kullanım Koşulları"
                    contentExtraClass="max-w-screen-lg"
                    renderTrigger={(openModal) => (
                      <span
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          openModal();
                        }}
                        className="underline cursor-pointer text-neutral-500"
                      >
                        Kullanım Koşulları'nı
                      </span>
                    )}
                    renderContent={() => (
                      <div>
                        <div className="text-md text-neutral-500 space-y-4">
                          <p className="text-justify">
                            Proident laborum officia ipsum do. Dolor ex culpa
                            nostrud proident laborum ut aliqua pariatur proident ut
                            pariatur qui ullamco Lorem. Deserunt consequat culpa
                            magna adipisicing sunt laborum. Aute Lorem ex dolore
                            magna anim sint duis anim esse nostrud ea.
                          </p>
                          <p className="text-justify">
                            Esse officia proident sunt ex cupidatat proident nisi
                            dolore dolor anim pariatur sint. Laboris excepteur est
                            dolor laboris est exercitation consequat in velit.
                            Occaecat aute exercitation in non officia consequat
                            occaecat duis ullamco consequat incididunt eiusmod.
                            Labore enim consequat fugiat laboris ullamco id
                            reprehenderit ipsum ad. Reprehenderit reprehenderit
                            deserunt fugiat adipisicing aliquip eiusmod quis.
                          </p>
                          <p className="text-jusify">
                            Veniam consectetur pariatur fugiat eiusmod. Excepteur
                            exercitation tempor minim elit aliqua eu eiusmod qui
                            magna deserunt nostrud Lorem labore. Velit duis occaecat
                            pariatur eu enim consequat eu laborum anim cupidatat
                            dolor deserunt id enim. Adipisicing et veniam duis
                            fugiat nisi nostrud. Nostrud excepteur consectetur
                            labore commodo veniam eu quis reprehenderit adipisicing
                            veniam.
                          </p>
                        </div>
                      </div>
                    )}
                  />{" "}
                  kabul ediyorum.
                </p>
              </div>

              {/* Ödeme Butonu */}

              <div className="space-y-4">
                <ButtonSecondary
                  onClick={() => {
                    handleProceedButton();
                  }}
                  className="w-full hover:bg-neutral-50 dark:hover:bg-black/20"
                >
                  <div className="flex items-center justify-center space-x-2">
                    <span className="text-xl font-semibold text-secondary-6000 break-normal">
                      {buttonMessage}
                    </span>
                    <i className="text-xl las la-angle-right text-secondary-6000"></i>
                  </div>
                </ButtonSecondary>
                <p className="text-sm text-neutral-500 text-center">
                  TODOGO üzerinden yapılan işlemler güvenlik sertifikalarıyla
                  korunmaktadır.
                  <i className="text-2xl las la-user-shield ml-1 text-secondary-6000"></i>
                </p>
              </div>
              
            </div>
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 sticky mt-10">
              <h3 className="text-2xl font-semibold">Rezervasyon Bilgileri</h3>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="space-y-4">
              <ButtonSecondary 
      className="w-full cursor-default"
      disabled={true}

      >
      <div className="flex items-center justify-center space-x-2">
        <span className="text-xl font-semibold text-secondary-6000 break-normal">
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </span>
      </div>
      </ButtonSecondary>
                <p className="text-sm text-neutral-500 text-center">
                  TODOGO bu koltuğu sizin için ayırdı. Lütfen size ayırılmış süre
                  içerisinde ödeme yapınız.
                  <i className="text-2xl las la-user-clock ml-1 text-secondary-6000"></i>
                </p>
              </div>
            </div>
          </div>
        );
      }

  };

  const renderPassengerInfo = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">Yolcu Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        {flightData!.passengers.map((p, index) => {
          // Her selectbox için kullanılabilir yolcuları filtrele
          const availablePassengers = savedPassengers.filter(savedPassenger => {
            // Diğer selectbox'larda seçili olmayan yolcuları göster
            const isSelectedByOthers = Object.entries(selectedPassengers).some(
              ([otherIndex, selectedId]) => 
                parseInt(otherIndex) !== index && // Mevcut selectbox değilse
                selectedId === savedPassenger.id.toString() // Ve bu yolcu seçili ise
            );
            return !isSelectedByOthers;
          });

          return (
            <div key={index} className="mb-1">
              <div className="flex justify-between items-center mb-5 mt-7">
                <h4 className="text-lg font-medium">{index + 1}. {getPassengerTypeName(p.PassengerType)}</h4>
                {savedPassengers.length > 0 && (
                  <div className="flex-1 max-w-xs ml-4">
                    <select
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      onChange={(e) => handleSavedPassengerSelect(index, e.target.value)}
                      value={selectedPassengers[index] || ""}
                    >
                      <option value="">Kayıtlı Yolcu Seç</option>
                      {availablePassengers.map((savedPassenger) => (
                        <option key={savedPassenger.id} value={savedPassenger.id}>
                          {savedPassenger.firstName} {savedPassenger.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="space-y-5">
                <div className="flex space-x-5">
                  {renderInput(`passengerName${index}`, "Ad", "Ad")}
                  {renderInput(`passengerSurname${index}`, "Soyad", "Soyad")}
                </div>
                <div className="flex space-x-5">
                  {renderInput(
                    `passengerBirthDate${index}`,
                    "Doğum Tarihi",
                    "GG/AA/YYYY"
                  )}
                  <div className="flex-1 space-y-1">
                    <Label>Uyruk</Label>
                    <Select
                      name={`passengerNationality${index}`}
                      value={
                        formData[`passengerNationality${index}`] || "TR"
                      }
                      onChange={handleInputChange}
                      className="border-neutral-200 focus:border-neutral-200 focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-200 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                      disabled={!paymentInputsDisabled}
                    >
                      <option value="TR">Türkiye</option>
                      <option value="OTHER">Diğer Ülkeler</option>
                    </Select>
                  </div>
                </div>
                <div className="flex space-x-5">
                  {formData[`passengerNationality${index}`] === "TR" ?                   renderInput(
                      `passengerIdentityNumber${index}`,
                      "T.C. Kimlik Numarası",
                      "T.C. Kimlik Numarası"
                    ) : null}
                  {!flightData!.selectedDepartFlight!.flightLocations.arrivalLocation.includes("Türkiye") || formData[`passengerNationality${index}`] !== "TR" ? (
                    <>
                      <div className="flex-1 space-y-1">
                        <Label>Pasaport Numarası</Label>
                        <Input
                          type="text"
                          name={`passengerPassportNumber${index}`}
                          placeholder={
                            formErrors[`passengerPassportNumber${index}`]
                              ? "Lütfen bu alanı doldurun."
                              : "Pasaport Numarası"
                          }
                          value={
                            formData[`passengerPassportNumber${index}`] || ""
                          }
                          onChange={handleInputChange}
                          className={
                            formErrors[`passengerPassportNumber${index}`]
                              ? "placeholder-red-400"
                              : ""
                          }
                        />
                      </div>
                      <div className="flex-1 space-y-1">
                        <Label>Pasaport S.K.T.</Label>
                        <Input
                          type="text"
                          name={`passengerPassportExpiry${index}`}
                          placeholder={
                            formErrors[`passengerPassportExpiry${index}`]
                              ? "Lütfen bu alanı doldurun."
                              : "GG/AA/YYYY"
                          }
                          value={
                            formData[`passengerPassportExpiry${index}`] || ""
                          }
                          onChange={handleInputChange}
                          className={
                            formErrors[`passengerPassportExpiry${index}`]
                              ? "placeholder-red-400"
                              : ""
                          }
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="flex-1 space-y-1">
                    <Label>Cinsiyet</Label>
                    <Select
                      name={`passengerGender${index}`}
                      value={formData[`passengerGender${index}`] || ""}
                      onChange={handleInputChange}
                      className={
                        formErrors[`passengerGender${index}`]
                          ? "text-red-400"
                          : ""
                      }
                      disabled={!paymentInputsDisabled}
                    >
                      <option
                        value=""
                        className={
                          formErrors[`passengerGender${index}`]
                            ? "text-red-400"
                            : ""
                        }
                      >
                        {formErrors[`passengerGender${index}`]
                          ? "Lütfen bu alanı doldurun."
                          : "Cinsiyet"}
                      </option>
                      <option value="male">Erkek</option>
                      <option value="female">Kadın</option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
  const renderContactInfo = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">İletişim Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        <div className="mb-1">
          <div className="space-y-5">
            <div className="flex space-x-5">
              {renderInput("contactName", "Ad", "Ad")}
              {renderInput("contactSurname", "Soyad", "Soyad")}
            </div>
            <div className="flex space-x-5">
            <div className="flex-1 space-y-1">
      <Label>Telefon Numarası</Label>
      <div className="flex relative w-100">
        <IntlTelInput
          ref={intlTelInputRef}
          initialValue={countryCode}
          onChangeNumber={setPhoneValue}
          onChangeValidity={setIsPhoneValid}
          onChangeCountry={(e) => {
            // Ülke seçici açıldığında search input'u bul ve placeholder'ı değiştir
            setTimeout(() => {
              const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
              if (searchInput) {
                searchInput.placeholder = "Ara";
              }
            }, 0);
          }}
          onChangeErrorCode={(e) => {
            if (formErrors['contactPhone'] && e !== null) {
              const input = document.querySelector('.iti__tel-input');
              input?.setAttribute('placeholder', e == 2 ? "Lütfen bu alanı doldurun." : errorMap[e]);
              input?.classList.add('placeholder-red-400');
            }
          }}
          initOptions={{
            initialCountry: "tr",
            separateDialCode: true,
            strictMode: true,
            loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
            dropdownContainer: document.body
          }}
          disabled={!paymentInputsDisabled}

        />
      </div>
    </div>
              
              {renderInput("contactEmail", "E-posta Adresi", "E-posta Adresi")}
            </div>
            <div className="text-sm text-neutral-500">
              Bilet bilgileriniz ve uçuş detaylarınız, ücretsiz SMS ve e-posta
              ile tarafınıza iletilecektir.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">Fatura Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        <div className="mb-5">
          <div className="space-y-5">
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Fatura Tipi</Label>
                <Select
                  name="invoiceType"
                  value={formData.invoiceType || "Bireysel"}
                  onChange={handleInputChange}
                  className="nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                  disabled={!paymentInputsDisabled}
                >
                  <option value="Bireysel">Bireysel</option>
                  <option value="Kurumsal">Kurumsal</option>
                </Select>
              </div>
              {renderInput("invoiceCity", "İl", "İl")}
            </div>
            <div className="flex space-x-5">
              {renderInput("billingName", formData.invoiceType === "Kurumsal" ? "Firma Adı" : "Ad Soyad", formData.invoiceType === "Kurumsal" ? "Firma Adı" : "Ad Soyad")}
              {renderInput("invoiceDistrict", "İlçe", "İlçe")}
              {renderInput("invoiceAddress", "Adres", "Adres")}
            </div>
            {formData.invoiceType === "Kurumsal" && (
              <div className="flex space-x-5">
                {renderInput("taxOffice", "Vergi Dairesi", "Vergi Dairesi")}
                {renderInput("taxNumber", "Vergi Numarası", "Vergi Numarası")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    if (!flightData?.isAllocated) {
      return (
        <div className="fixed inset-0 bg-white/95 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-10 shadow-xl max-w-xl w-full mx-4">
            <div className="flex items-center justify-between mb-6">
              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-departure text-6xl text-neutral-500"></i>
                <span className="text-xl font-medium text-neutral-500">
                  {flightData?.selectedDepartFlight!.flightLocations.departureLocationCode}
                </span>
              </div>

              <div className="w-64 mx-12 relative">
                <div className="absolute top-1/2 left-0 right-0">
                  <div className="h-1 w-full bg-neutral-100 rounded">
                    <div className="h-1 bg-neutral-500 rounded animate-progress-soft"></div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center space-y-2">
                <i className="las la-plane-arrival text-6xl text-neutral-500"></i>
                <span className="text-xl font-medium text-neutral-500">
                  {flightData?.selectedDepartFlight!.flightLocations.arrivalLocationCode}
                </span>
              </div>
            </div>

            <div className="text-center space-y-6">
              <h3 className="text-xl font-medium text-neutral-800">
              Yönlendiriliyorsunuz
              </h3>
              <div className="flex justify-center">
                <div className="w-12 h-12 border-4 border-neutral-200 border-t-neutral-500 rounded-full animate-spin"></div>
              </div>
              <p className="text-md text-neutral-500">
              Bu işlem birkaç saniye sürebilir, lütfen bekleyin.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h3 className="text-2xl font-semibold">Uçuş Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Uçuş Bilgileri */}
        <div className="space-y-6">
          {flightData!.selectedDepartFlight && (
            <>
              <div className="-mb-6">
                <Heading2
                  heading={
                    <div className="flex justify-center text-xl font-medium">
                      {`${getLocationWithoutParentheses(
                        flightData!.selectedDepartFlight.flightLocations.departureLocation
                      )} - ${getLocationWithoutParentheses(
                        flightData!.selectedDepartFlight.flightLocations.arrivalLocation
                      )}`}
                    </div>
                  }
                  subHeading={
                    <span className="block items-center text-base text-md text-neutral-500 text-center mt-4">
                      <span>
                        {formatDateObject(flightData!.selectedDepartFlight.flightDates.departureDate)}
                        {flightData!.selectedDepartFlight.flightDates.returnDate ? formatDateObject(flightData!.selectedDepartFlight.flightDates.returnDate) &&
                          ` - ${formatDateObject(flightData!.selectedDepartFlight.flightDates.returnDate)}` : ''}
                      </span>
                      <span className="mx-2">·</span>
                      {flightData!.selectedDepartFlight.flightDirection}
                      {flightData!.selectedDepartFlight.flightClass && (
                        <>
                        <span className="mx-2">·</span>
                        {flightData!.selectedDepartFlight.flightClass}
                        </>
                        )}
                      <span className="mx-2">·</span>
                      {flightData!.selectedDepartFlight.flightGuests.totalGuests} Yolcu
                    </span>
                  }
                />
              </div>

              <h3 className="text-1xl font-semibold">Gidiş Uçuşu</h3>
              <CheckoutFlightCard flight={flightData!.selectedDepartFlight} type="Gidiş" />
            </>
          )}
          {flightData!.selectedReturnFlight && (
            <>
              <h3 className="text-1xl font-semibold">Dönüş Uçuşu</h3>
              <CheckoutFlightCard flight={flightData!.selectedReturnFlight} type="Dönüş" />
            </>
          )}
        </div>
        {flightData?.selectedDepartFlight && flightData?.passengers && renderPassengerInfo()}

        {renderContactInfo()}

        { renderInvoiceInfo() }
      </div>
    );
  };

  useEffect(() => {
    // Eğer kullanıcı giriş yapmışsa kayıtlı yolcuları getir
    const user = getCurrentUser();
    if (user) {
      loadSavedPassengers();
    }
  }, []);

  const loadSavedPassengers = async () => {
    try {
      const passengers = await getSavedPassengers();
      setSavedPassengers(passengers);
    } catch (err) {
      console.error("Kayıtlı yolcular yüklenirken hata oluştu:", err);
    }
  };

  const handleSavedPassengerSelect = (passengerIndex: number, savedPassengerId: string) => {
    if (savedPassengerId === "") {
      // Seçim kaldırıldığında state'ten de kaldır
      const newSelectedPassengers = { ...selectedPassengers };
      delete newSelectedPassengers[passengerIndex];
      setSelectedPassengers(newSelectedPassengers);
      return;
    }

    const savedPassenger = savedPassengers.find(p => p.id === parseInt(savedPassengerId));
    if (!savedPassenger) return;

    // Seçilen yolcuyu state'e kaydet
    setSelectedPassengers(prev => ({
      ...prev,
      [passengerIndex]: savedPassengerId
    }));

    // Doğum tarihini formatla
    const birthDate = new Date(savedPassenger.dateOfBirth);
    const formattedBirthDate = `${birthDate.getDate().toString().padStart(2, '0')}/${(birthDate.getMonth() + 1).toString().padStart(2, '0')}/${birthDate.getFullYear()}`;

    const newFormData = {
      ...formData,
      [`passengerName${passengerIndex}`]: savedPassenger.firstName,
      [`passengerSurname${passengerIndex}`]: savedPassenger.lastName,
      [`passengerBirthDate${passengerIndex}`]: formattedBirthDate,
      [`passengerGender${passengerIndex}`]: savedPassenger.gender == 1 ? "male" : "female",
      [`passengerNationality${passengerIndex}`]: savedPassenger.nationality,
      [`passengerIdentityNumber${passengerIndex}`]: savedPassenger.identityNumber,
    };

    // Eğer ilk yolcu ise iletişim bilgilerini de güncelle
    if (passengerIndex === 0) {
      newFormData.contactName = savedPassenger.firstName;
      newFormData.contactSurname = savedPassenger.lastName;
      newFormData.contactEmail = savedPassenger.email || "";
      if (savedPassenger.phoneNumber) {
        setPhoneValue(savedPassenger.phoneNumber);
        if (intlTelInputRef.current) {
          intlTelInputRef.current.getInstance().setNumber(savedPassenger.phoneNumber);
        }
      }
    }

    setFormData(newFormData);
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-24 mb-24 flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 mb-10 lg:mb-0">
          {renderMain()}
        </div>
        <div className={`w-full lg:w-2/5 xl:w-1/3 ${paymentClassName}`}>
          {renderSidebar(paymentClassName)}
        </div>
      </main>
      {renderTimeoutModal()}
      <ToastContainer stacked />
    </div>
  );
};

export default CheckOutPagePageMain;
