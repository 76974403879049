/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, ReactNode } from "react";
import imagePng from "images/hero-right2.png";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Otel" | "Experiences" | "Transfer" | "Uçak";
  currentTab: SearchTab;
  rightImage?: string;
  formFlightData?: any;
  formStayData?: any;
  arrivalLocation?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
  formFlightData,
  formStayData,
  arrivalLocation,
}) => {

  return (
    <div
      className={`nc-SectionHeroArchivePage mt-36 flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm 
          currentPage={currentPage}
          currentTab={currentTab}
          formFlightData={formFlightData}
          formStayData={formStayData}
        />
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
