/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import StayGuestInput from "../StayGuestInput";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonSubmit from "../ButtonSubmit";
import ButtonClear from "../ButtonClear";
import { useNavigate } from "react-router-dom";
import { SearchItem } from "types/common.types";

const StaySearchForm = ({ isHotel = true, onSubmit }: { isHotel?: boolean, onSubmit?: () => void }) => {

  const navigate = useNavigate();

  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "guests" | "closed"
  >("closed");
  const [dropOffLocationType, setDropOffLocationType] = useState<
    "Tek Yön" | "Gidiş ve Dönüş"
  >("Tek Yön");
  const [stayLocation, setStayLocation] = useState("");
  const [guests, setGuests] = useState<GuestsObject[]>([
    { adults: 1, children: 0 },
  ]);
  const [stayError, setStayError] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setFieldNameShow("any" as any);
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const getTodayDate = () => {
    const today = new Date();
    return today.toLocaleDateString("tr-TR");
  };
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleAddRoom = () => {
    if (guests.length < 7) {
      setGuests([...guests, { adults: 1, children: 0 }]);
    }
  };

  const handleRemoveRoom = (index: number) => {
    const newRooms = guests.filter((_, roomIndex) => roomIndex !== index);
    setGuests(newRooms);
  };

  const handleStayChange = (value: SearchItem) => {
    setStayLocation(value.display);
    if (value.display === "") {
      setStayError(false);
    } else {
      setStayError(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let hasError = false;

    if (!stayLocation) {
      setStayError(true);
      hasError = true;
    } else {
      setStayError(false);
    }

    if (hasError) {
      return;
    }

    const todayDate = getTodayDate();

    const formStayData = {
      stayLocation,
      stayDates: {
        startDate: startDate
          ? startDate.toLocaleDateString("tr-TR")
          : todayDate,
        endDate: endDate ? endDate.toLocaleDateString("tr-TR") : todayDate,
      },
      stayGuests: {
        guests,
        adultGuests: guests.reduce(
          (total, room) => total + (room.adults || 0),
          0
        ),
        childrenGuests: guests.reduce(
          (total, room) => total + (room.children || 0),
          0
        ),
        totalGuests: guests.reduce(
          (total, room) => total + (room.adults || 0) + (room.children || 0),
          0
        ),
        totalRooms: guests.length,
      },
    };
    onSubmit && onSubmit();
    navigate("/listing-stay-map", { state: formStayData });
  };

  const handleClear = () => {
    setStayLocation("");
    setGuests([{ adults: 1, children: 0 }]);
    setStartDate(new Date());
    setEndDate(new Date());
    setStayError(false);
    setFieldNameShow("closed");
  };

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("location")}
          >
            <span
              className={`text-neutral-400 ${stayError ? "text-red-400" : ""}`}
            >
              {stayError
                ? "Lütfen bu alanı doldurun."
                : "Nereye gitmek istiyorsunuz?"}
            </span>
            <span>{stayLocation || "Nereye"}</span>
          </button>
        ) : (
          <LocationInput
            defaultValue={stayLocation}
            onChange={handleStayChange}
            type="hotel"
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">
              {isHotel
                ? "Giriş ve Çıkış Tarihiniz"
                : dropOffLocationType === "Tek Yön"
                ? "Gidiş Tarihiniz"
                : "Gidiş ve Dönüş Tarihiniz"}
            </span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Tarih Ekle"}
            </span>
          </button>
        ) : (
          <DatesRangeInput
            dateSelectCallback={(dates) => {
              if (dates && Array.isArray(dates) && dates.length === 2) {
                setStartDate(dates[0] as Date);
                setEndDate(dates[1] as Date);
              }
            }}
            dropOffLocationType={dropOffLocationType}
            isHotel={isHotel}
            isTransfer={false}
          />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";

    const totalGuests = guests.reduce(
      (total, room) => total + (room.adults || 0) + (room.children || 0),
      0
    );
    const roomCount = guests.length;

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Misafirler</span>
            <span>
              {roomCount > 0
                ? `${roomCount} Oda, ${totalGuests} Misafir`
                : `1 Oda, 1 Misafir`}
            </span>
          </button>
        ) : (
          <div>
            {guests.map((room, index) => (
              <div key={index} className="border-b p-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-base font-semibold">{index + 1}. Oda</h3>
                  {index > 0 && (
                    <button
                      onClick={() => handleRemoveRoom(index)}
                      className="text-xs text-neutral-500 dark:text-neutral-400 font-normal"
                    >
                      Oda Kaldır
                    </button>
                  )}
                </div>
                <StayGuestInput
                  defaultValue={room}
                  onChange={(updatedRoom: GuestsObject) => {
                    const newRooms = [...guests];
                    newRooms[index] = updatedRoom;
                    setGuests(newRooms);
                  }}
                />
                <div className="flex justify-between items-center mt-5">
                  {guests.length < 7 && (
                    <button
                      onClick={handleAddRoom}
                      className="text-xs text-neutral-500 dark:text-neutral-400 font-normal"
                    >
                      Oda Ekle
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <div className="w-full space-y-5">
        {renderInputLocation()}
        {renderInputGuests()}
        {renderInputDates()}
      </div>
      <div className="px-4 py-3 flex justify-between rounded-2xl mt-2">
        <ButtonClear onClick={handleClear} className="mr-2" />
        <ButtonSubmit onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default StaySearchForm;
