import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { login, getCurrentUser } from "services/auth.service";
import { LoginRequestDto } from "types/auth.types";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<LoginRequestDto>({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Kullanıcı giriş yapmışsa hesabım sayfasına yönlendir
    if (getCurrentUser()) {
      navigate("/account");
    }
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      await login(formData);
      // Login başarılı olduğunda authChange event'ini tetikle
      window.dispatchEvent(new Event('authChange'));
      
      // Önceki sayfaya yönlendir
      const from = location.state?.from;
      if (from?.pathname?.includes('/flight-checkout')) {
        // Checkout sayfasına state ile birlikte yönlendir
        navigate(from.pathname, { state: from.state });
      } else {
        navigate(from?.pathname || '/');
      }
    } catch (err: any) {
      console.log(err);
      setError(err.response?.data?.error || "Giriş yapılırken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>TODOGO | Giriş Yap</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Giriş Yap
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="text-sm text-red-700">{error}</div>
            </div>
          )}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                E-posta Adresi
              </span>
              <Input
                type="email"
                placeholder="ornek@ornek.com"
                className="mt-1"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Şifre
              </span>
              <Input 
                type="password" 
                className="mt-1"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <ButtonPrimary type="submit" disabled={isLoading}>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                  Giriş Yapılıyor...
                </div>
              ) : (
                "Giriş Yap"
              )}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Hesabınız yok mu? {` `}
            <Link to="/signup" className="text-primary-6000">Üye Ol</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
