import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { register, getCurrentUser } from "services/auth.service";
import { RegisterRequestDto } from "types/auth.types";
import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/build/css/intlTelInput.css';
import Label from "components/Label/Label";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<RegisterRequestDto>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState<string>("+90");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
 
  let isVerificationActive = false;

  useEffect(() => {
    // Kullanıcı giriş yapmışsa hesabım sayfasına yönlendir
    if (getCurrentUser()) {
      navigate("/account");
    }
  }, [navigate]);

  const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!isPhoneValid) {
      setError("Lütfen geçerli bir telefon numarası giriniz.");
      return;
    }

    setIsLoading(true);

    try {
      const submitData = {
        ...formData,
        phoneNumber: phoneValue ? `${phoneValue}` : undefined
      };
      await register(submitData);
      if (isVerificationActive) {
        setShowVerification(true); // Kayıt başarılı olduğunda doğrulama ekranını göster
      }else{
        window.dispatchEvent(new Event('authChange'));
        navigate("/");
      }
    } catch (err: any) {
      setError(err.response?.data?.error || "Kayıt olurken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setVerificationError("");
    
    if (!verificationCode) {
      setVerificationError("Lütfen doğrulama kodunu giriniz.");
      return;
    }

    setIsVerificationLoading(true);

    try {
      // Backend hazır olmadığı için simüle ediyoruz
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Başarılı doğrulama sonrası
      window.dispatchEvent(new Event('authChange'));
      navigate("/");
    } catch (err: any) {
      setVerificationError(err.response?.data?.error || "Doğrulama kodu hatalı");
    } finally {
      setIsVerificationLoading(false);
    }
  };

  if (showVerification) {
    return (
      <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
        <Helmet>
          <title>TODOGO | E-posta Doğrulama</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            E-posta Doğrulama
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            {verificationError && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="text-sm text-red-700">{verificationError}</div>
              </div>
            )}
            <form className="grid grid-cols-1 gap-6" onSubmit={handleVerificationSubmit}>
              <div className="text-center">
                <p className="text-neutral-500 dark:text-neutral-400 text-sm mb-8">
                  Lütfen {formData.email} adresine gönderilen doğrulama kodunu giriniz.
                </p>
              </div>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Doğrulama Kodu
                </span>
                <Input
                  type="text"
                  placeholder="Doğrulama kodunu giriniz"
                  className="mt-1"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                  disabled={isVerificationLoading}
                />
              </label>
              <ButtonPrimary type="submit" disabled={isVerificationLoading}>
                {isVerificationLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                    Doğrulanıyor...
                  </div>
                ) : (
                  "Doğrula"
                )}
              </ButtonPrimary>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>TODOGO | Üye Ol</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Üye Ol
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="text-sm text-red-700">{error}</div>
            </div>
          )}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Ad
              </span>
              <Input
                type="text"
                placeholder="Adınız"
                className="mt-1"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Soyad
              </span>
              <Input
                type="text"
                placeholder="Soyadınız"
                className="mt-1"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <div className="block">
              <Label>Telefon Numarası</Label>
              <div className="flex relative w-100">
                <IntlTelInput
                  initialValue={countryCode}
                  onChangeNumber={setPhoneValue}
                  onChangeValidity={setIsPhoneValid}
                  onChangeCountry={(e) => {
                    setTimeout(() => {
                      const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
                      if (searchInput) {
                        searchInput.placeholder = "Ara";
                      }
                    }, 0);
                  }}
                  onChangeErrorCode={(e) => {
                    if (e !== null) {
                      const input = document.querySelector('.iti__tel-input');
                      input?.setAttribute('placeholder', e == 2 ? "Lütfen bu alanı doldurun." : errorMap[e]);
                      input?.classList.add('placeholder-red-400');
                    }
                  }}
                  initOptions={{
                    initialCountry: "tr",
                    separateDialCode: true,
                    strictMode: true,
                    loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
                    dropdownContainer: document.body
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                E-posta Adresi
              </span>
              <Input
                type="email"
                placeholder="ornek@ornek.com"
                className="mt-1"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Şifre
              </span>
              <Input 
                type="password" 
                className="mt-1"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
                disabled={isLoading}
              />
            </label>
            <ButtonPrimary type="submit" disabled={isLoading}>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                  Kayıt Yapılıyor...
                </div>
              ) : (
                "Üye Ol"
              )}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Zaten üye misiniz? {` `}
            <Link to="/login" className="text-primary-6000">Giriş Yap</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
