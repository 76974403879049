import axios from '../utils/axios';
import { CreateSavedPassengerModel, SavedPassenger } from '../types/auth.types';

export const getSavedPassengers = async (): Promise<SavedPassenger[]> => {
    const response = await axios.get(`/api/saved-passengers`);
    return response.data;
};

export const createSavedPassenger = async (data: CreateSavedPassengerModel): Promise<SavedPassenger> => {
    const response = await axios.post(`/api/saved-passengers`, data);
    return response.data;
};

export const updateSavedPassenger = async (id: string, data: CreateSavedPassengerModel): Promise<SavedPassenger> => {
    const response = await axios.put(`/api/saved-passengers/${id}`, data);
    return response.data;
};

export const deleteSavedPassenger = async (id: string): Promise<void> => {
    await axios.delete(`/api/saved-passengers/${id}`);
}; 