import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "services/auth.service";
import { SavedPassenger } from "types/auth.types";
import {
  getSavedPassengers,
  createSavedPassenger,
  updateSavedPassenger,
  deleteSavedPassenger,
} from "services/passenger.service";
import { Helmet } from "react-helmet-async";
import CommonLayout from "./CommonLayout";

export interface AccountPassengersProps {
  className?: string;
}

const AccountPassengers: React.FC<AccountPassengersProps> = ({ className }) => {
    const navigate = useNavigate();
    const [savedPassengers, setSavedPassengers] = useState<SavedPassenger[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const currentUser = getCurrentUser();
  
    useEffect(() => {
      if (!currentUser) {
        navigate("/login");
        return;
      }
  
      loadSavedPassengers();
    }, [navigate]);
  
    const loadSavedPassengers = async () => {
      try {
        const passengers = await getSavedPassengers();
        setSavedPassengers(passengers);
      } catch (err: any) {
        setError(err.response?.data?.message || "Yolcular yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleDeletePassenger = async (id: string) => {
      if (window.confirm("Bu yolcuyu silmek istediğinizden emin misiniz?")) {
        try {
          await deleteSavedPassenger(id);
          setSavedPassengers(savedPassengers.filter((p) => p.id !== parseInt(id)));
        } catch (err: any) {
          setError(err.response?.data?.message || "Yolcu silinirken bir hata oluştu");
        }
      }
    };
  return <div className={`nc-AccountPassengers ${className}`} data-nc-id="AccountPassengersPage">
    <Helmet>
        <title>TODOGO | Yolcularım</title>
    </Helmet>
    <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          
          <h2 className="text-3xl font-semibold">Yolcularım</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          
            <button
                onClick={() => navigate("/account-passengers/new")}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
              >
                Yeni Yolcu Ekle
              </button>
            <div className="grid grid-cols-1">
            {error && (
              <div className="rounded-md bg-red-50 p-4 mb-4">
                <div className="text-sm text-red-700">{error}</div>
              </div>
            )}

            {savedPassengers.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                Henüz kayıtlı yolcu bulunmuyor
              </div>
            ) : (
              <div className="space-y-4">
                {savedPassengers.map((passenger) => (
                  <div
                    key={passenger.id}
                    className="border rounded-lg p-4 flex justify-between items-center"
                  >
                    <div>
                      <div className="font-medium">
                        {passenger.firstName} {passenger.lastName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {passenger.passengerType} • {passenger.relation}
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => navigate(`/account-passengers/${passenger.id}`)}
                        className="text-primary-600 hover:text-primary-700"
                      >
                        Düzenle
                      </button>
                      <button
                        onClick={() => handleDeletePassenger(passenger.id.toString())}
                        className="text-red-600 hover:text-red-700"
                      >
                        Sil
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            </div>
        </div>

    </CommonLayout>
  </div>;
};

export default AccountPassengers;