import ReactGA from 'react-ga4';

export const logEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label
  });
};

// Özel event fonksiyonları
export const logCheckoutEvent = (checkoutType: 'flight' | 'stay') => {
  logEvent('Checkout', 'Started', checkoutType);
};

export const logSearchEvent = (searchType: string, searchTerm: string) => {
  logEvent('Search', searchType, searchTerm);
};

export const logListingView = (listingType: string, listingId: string) => {
  logEvent('Listing', 'View', `${listingType}-${listingId}`);
};

export const logFlightSearch = (searchData: any) => {
  ReactGA.event({
    category: 'Flight',
    action: 'Search',
    label: `${searchData.departureCode}-${searchData.arrivalCode}`,
  }, searchData);
};