import React, { FC, useState, useEffect } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [marginTop, setMarginTop] = useState("200px");

  var localStorageFormFlightData = localStorage.getItem("formFlightData");
  if (localStorageFormFlightData){
    var formFlightData = JSON.parse(localStorageFormFlightData);
    formFlightData.flightDates.departureDate = new Date(formFlightData.flightDates.departureDate);
    if (formFlightData.flightDates.returnDate)
      formFlightData.flightDates.returnDate = new Date(formFlightData.flightDates.returnDate);
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 1023) {
        setMarginTop("50px");
      } else if (width <= 1279) {
        setMarginTop("-120px");
      } else {
        setMarginTop("-250px");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:items-center">
        
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div
            className="flex-shrink-0 lg:w-1/2 flex flex-col items-center justify-center space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0"
            style={{ marginTop: marginTop }}
          >
            <h2 className="font-semibold text-4xl md:text-4xl xl:text-4xl !leading-[114%] text-center">
            Sırada Ne Var?
            </h2>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center"
            style={{ marginTop: '30px' }}
            >
        Keşfet, Planla, Sıradaki Macerana Hazır Ol: Todogo.com ile Yeni Bir Seyahat Deneyimi Başlıyor!
            </span>
            {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
          </div>
          <div className="flex-grow">
            <img className="w-full" src={imagePng} alt="hero" />
          </div>
        </div>
        
        <HeroSearchForm formFlightData={formFlightData} className="hidden lg:block w-full" marginTop={"-580px"} />
      </div>

    </div>
  );
};

export default SectionHero;